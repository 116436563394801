/* You can add global styles to this file, and also import other style files */

// BUG: Layer precedence is broken. CoreUI imports reset.css that overrides primeNG styles.
// Can not fix it with layer precedence because: https://github.com/angular/angular-cli/issues/26940
@layer coreui, primeng, custom;

$enable-ltr: true;
$enable-rtl: true;

// If you want to override variables do it here
@import 'variables';

// Import styles with default layout.
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';

// Some temp fixes
@import 'fixes';

// Prism.js
@import 'examples';

// PrimeNG
@import 'primeng/resources/themes/tailwind-light/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

// Mapbox
@import 'mapbox-gl/dist/mapbox-gl.css';

@layer custom {
    @import 'custom';
}
